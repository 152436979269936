import { getClient } from '@utils/sanity/sanity.server';
import { GetStaticProps } from 'next';
import React, { FunctionComponent } from 'react';

import FourOhFour from '../components/FourOhFour';
import globalQuery from '../utils/queries/globalQuery';

const Index: FunctionComponent = () => <FourOhFour />;

export const getStaticProps: GetStaticProps = async ({ locale, preview = null }) => {
  const global = await getClient(preview).fetch(globalQuery, { locale });
  const data = {};
  return { props: { data, global }, revalidate: 600 };
};

export default Index;
