import React from 'react';

type Props = {
  text?: string;
};

const FourOhFour = ({ text = 'Page not found' }: Props): JSX.Element => {
  return (
    <div className="sanity-error-page sanity-error-page--404">
      <div>{text}</div>
    </div>
  );
};

export default FourOhFour;
